.cardFooter {
    padding: 2% 7%;
    color: #646771;
    background-color: #000938 !important;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

ul>li {
    padding: 4px
}

ul>li:hover {
    color: #957bda;
    cursor: pointer
}

hr {
    border-width: 3px
}

.social>i {
    padding: 1%;
    font-size: 15px
}

.social>i:hover {
    color: #957bda;
    cursor: pointer
}

.policy>div {
    padding: 4px
}

.heading {
    font-family: 'Titillium Web', sans-serif;
    color: white
}

.divider {
    border-top: 2px solid rgba(189, 196, 203, 0.5);
}
.followUsIcon{
    color: rgb(20, 161, 204) ;
    font-size:  3em !important; 
}
#footera:hover{
    color: #62a6ef;
    text-decoration: none;
  }